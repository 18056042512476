import * as React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import SectionTitle from '../components/atoms/SectionTitle'
import HeadSection from '../components/atoms/HeadSection'
import IconsSection from '../components/atoms/IconsSection'
import CustomGallery from '../components/molecules/Gallery'
import HighStandardsSection from '../components/molecules/HighStandardsSection'
import SurroundingsForWinterHolidaysSection from '../components/molecules/SurroundingsForWinterHolidaysSection'
import MapAndStreetViewSection from '../components/molecules/MapAndStreetViewSection'

const WinterHolidaysPage = (props) => {
  const { translations, path, } = props
  const classes = useStyles()
  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
    <div className={classes.pageConainer}>
      <HeadSection color='#04bafc' content={translations.head_section} />
      <SectionTitle title={translations.main_section_title} subtitle={translations.main_section_subtitle} color='#04bafc' backgroundColor='#04bafc' />
      <IconsSection color='#02bafc'/>
      <div className={classes.imgSection}>
        <div className={classes.leftCol}>
          <div className={classes.title}>
            <h3>{translations.image_section_title}</h3>
          </div>
          <div className={classes.content}>
            <p>
              {translations.image_section_p_1}
            </p>
            <p>
              {translations.image_section_p_2}
            </p>
          </div>
        </div>
        <div className={classes.rightCol}>
          <CustomGallery page='winter-holidays'/>
        </div>
      </div>
      <HighStandardsSection color='#04bafc' backgroundColor='#04bafccc' borderColor='#04AAFC'/>
      <SurroundingsForWinterHolidaysSection color='#04bafc' backgroundColor='#04bafc'/>
    </div>
    <MapAndStreetViewSection/>
  </Layout>
  )
}

const useStyles = createUseStyles({
  pageConainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  imgSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '3em',
    textAlign: 'justify',
  },
  leftCol: {
    width: '50%',
  },
  rightCol: {
    width: '45%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '40px',
  },
  title: {
    color: '#04bafc',
    width: '100%',
    position: 'relative',
    '& h3': {
      fontSize: '22px',
      fontWeight: '700',
    },
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: '30px',
      height: '2px',
      backgroundColor: '#04bafc',
      bottom: '-15px',
      left: '0px',
    },
  },
  content: {
    fontSize: '20px',
    marginTop: '2em',
  },
  '@media (max-width: 960px)': {
    leftCol: {
      width: '100%',
    },
    rightCol: {
      width: '100%',
    },
  },
  '@media(min-width: 1400px)': {
    pageConainer: {
      minWidth: '1400px',
    },
  },
})

WinterHolidaysPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.winter_holidays,
  }
}

export default connect(mapStateToProps)(WinterHolidaysPage)
