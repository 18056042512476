import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

const IconsSection = props => {
  const { translations, color, } = props
  const classes = useStyles({ color, })

  return (
    <div className={classes.iconsSection}>
      <div className={classes.boxWithIcon}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <div className={classes.info}>
          <span><h4>{translations.adress_box.addres}</h4></span>
          <span> {translations.adress_box.addres_first_line} <br /> {translations.adress_box.addres_second_line} </span>
        </div>
      </div>
      <div className={classes.boxWithIcon}>
        <FontAwesomeIcon icon={faPhone} />
        <div className={classes.info}>
          <span><h4>{translations.call_us_box.call_us}</h4></span>
          <span> {translations.call_us_box.call_us_first_line} <br /> {translations.call_us_box.call_us_second_line}</span>
        </div>
      </div>
      <div className={classes.boxWithIcon}>
        <FontAwesomeIcon icon={faEnvelope} />
        <div className={classes.info}>
          <span><h4>{translations.email_box.email_title}</h4></span>
          <span> {translations.email_box.email} <br /><br /></span>
        </div>
      </div>
    </div>
  )
}

IconsSection.propTypes = {
  translations: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  iconsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '3em',
  },

  boxWithIcon: props => ({
    marginBottom: '1em',
    display: 'flex',
    padding: '1.3em',
    paddingLeft: '0em',
    '& svg': {
      height: '5em',
      width: '5em !important',
      color: 'white',
      padding: '1.2em',
      backgroundColor: props.color,
    },
  }),
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1em',
    fontSize: '16px',
    lineHeight: '21px',
    '& h4': {
      display: 'inline',
      color: '#6B7671',
    },
  },

})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.contact_page,
  }
}

export default connect(mapStateToProps)(IconsSection)
