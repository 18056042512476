import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import SectionTitle from '../atoms/SectionTitle'
import { connect } from 'react-redux'
import GoogleMap from './GoogleMap'
import DefaultMapMarker from '../atoms/DefaultMapMarker'

const SurroundingsForWinterHolidaysSection= props => {
  const { translations, backgroundColor } = props
  const classes = useStyles(backgroundColor)
  const geoCodes = [
    { lat: 50.0618971, lng: 19.9345672, },
    { lat: 49.9823327, lng: 20.0579787, },
    { lat: 49.9690762, lng: 20.4277796, },
    { lat: 49.2759821, lng: 19.9038363, }
  ]
  return (
    <div>
      <SectionTitle title={translations.title} subtitle={translations.subtitle} color={props.color} backgroundColor={props.backgroundColor} paddingTop='70px'/>
        <div className={classes.standardSection}>
            <div className={classes.attractionsBox}>
              <div className={classes.attractionsBoxHalf}>
                <div className={classes.attractionsBoxFourth}>
                  <div className={classes.attractionsTitle}>
                    {translations.attractions}
                  </div>
                  <div style={{width: '30px', margin: '15px 0 15px 0', height: '2px', backgroundColor: `${props.backgroundColor}`,}}></div>
                  <ul className={classes.attractionsUl}>
                      <li className={classes.attractionsLi}>
                        <li style={{minWidth: '300px'}}> {translations.attractions_do.castle} </li>
                        <li style={{minWidth: '300px'}}> {translations.attractions_do.lake_dam} </li>
                        <li style={{minWidth: '300px'}}> {translations.attractions_do.hiking} </li>
                        <li style={{minWidth: '300px'}}> {translations.attractions_do.cultural_events} </li>
                        <li style={{minWidth: '300px'}}> {translations.attractions_do.disco} </li>
                      </li>
                  </ul>
                </div>
                <div className={classes.attractionsBoxFourth}>
                  <div className={classes.attractionsTitle}>
                      {translations.cities.cities}
                  </div>
                  <div style={{width: '30px', margin: '15px 0 15px 0', height: '2px', backgroundColor: `${props.backgroundColor}`,}}></div>
                  <ul className={classes.attractionsUl}>
                      <li className={classes.attractionsLi}>
                        <li style={{minWidth: '250px'}}> {translations.cities.wieliczka} </li>
                        <li style={{minWidth: '250px'}}> {translations.cities.krakow} </li>
                        <li style={{minWidth: '250px'}}> {translations.cities.bochnia} </li>
                        <li style={{minWidth: '250px'}}> {translations.cities.zakopane} </li>
                      </li>
                  </ul>
                </div>
              </div>
              <div className={classes.attractionsBoxHalf}>
                <div className={classes.attractionsTitle}>
                  {translations.location}
                <div style={{width: '30px', margin: '15px 0 15px 0', height: '2px', backgroundColor: `${props.backgroundColor}`}}>
                </div>
                  <div style={{color: '#a4a4a4', marginTop: '-3px', marginBottom: '10px'}} className={classes.attractionsTitle}>
                    {translations.location_subtitle}
                  </div>
                </div>
                <ul className={classes.attractionsUl}>
                    <li className={classes.attractionsLi}>
                      <li style={{minWidth: '300px'}}> {translations.cities_for_ski.siepraw} </li>
                      <li style={{minWidth: '300px'}} > {translations.cities_for_ski.kasinka} </li>
                      <li style={{minWidth: '300px'}}> {translations.cities_for_ski.rabka} </li>
                      <li style={{minWidth: '300px'}}> {translations.cities_for_ski.myslenice} </li>
                      <li style={{minWidth: '300px'}}> {translations.cities_for_ski.lubomierz} </li>
                    </li>
                </ul>
                </div>
              </div>
            <div className={classes.attractionsBox}>
              <GoogleMap
                mapCenter={{ lat: 49.881009, lng: 20.085285, }}
                zoom={8}
                customStyles={{ height: '80%', }}

              >
              {geoCodes.map(place => (
                <DefaultMapMarker
                  key={place.lat}
                  {...place}
                />
              ))}

            </GoogleMap>

          </div>
        </div>
    </div>
  )
}

SurroundingsForWinterHolidaysSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({

  standardSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '3em',
    justifyContent: 'center',
  },
  attractionsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '500px',
    minHeight: '300px',
    width: '50%',
    paddingLeft: '3px',
  },
  attractionsBoxHalf: {
    width: '100%',
  },
  attractionsBoxFourth: {
    width: '50%',
    minWidth: '290px',
    float: 'left',
    marginBottom: '3px',
  },
  attractionsTitle: {
    color: '#6b7671',
    fontSize: '20px',
    lineHeight: '20px',
    marginTop: '30px',
  },
  attractionsUl: {
    padding: '2px',
    margin: '0',
    lineHeight: '30px',
    marginLeft: '20px',
  },
  attractionsLi: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
    color: '#141414',
    fontSize: '18px',
    '& marker': {
      fontVariantNumeric: 'tabular-nums',
    },
  },
  '@media(max-width: 480px)': {
    attractionsBox: {
      minWidth: '280px',
    },
  },
  '@media(max-width: 320px)': {
      attractionsBox: {
        minWidth: '280px',
      },
  },
})

const mapStateToProps = state => {
  return {
    translations: state.translations.translations.surroundings_winter_holidays,
  }
}

export default connect(mapStateToProps)(SurroundingsForWinterHolidaysSection)
